import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import { useNotify } from '../context/NotificationContext';
import * as Yup from 'yup';
import { useFormik } from 'formik';

const PaymentsForm = () => {
    const { id } = useParams(); // Get payment ID from the URL
    const { getCookie, APP_URL } = useAuth()
    const { error, success } = useNotify()
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();

    const formik = useFormik({
        initialValues: {
            invoice_id: '',
            payment_amount: '',
        },
    });

    useEffect(() => {
        fetch(`${APP_URL}/payments/${id}`, {
            credentials: 'include',
        })
            .then((response) => {
                if (response.ok) {
                    response.json().then(data => formik.setValues({
                        invoice_id: data.invoice_id,
                        payment_amount: data.payment_amount
                    }))
                    setLoading(false)
                } else {
                    error(response)
                }
            })
            .catch((e) => {
                error(e)
            });
    }, [id]);

    const handleUpdate = () => {
        fetch(`${APP_URL}/payments/${id}`, {
            method: 'PATCH',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
                'X-CSRF-TOKEN': getCookie('csrf_access_token')
            },
            body: JSON.stringify(formik.values),
        })
            .then((response) => {
                if (response.ok) {
                    success('Payment updated successfully!');
                } else {
                    error('Failed to update payment');
                }
            })
            .catch((e) => error(e));
    };

    const handleDelete = () => {
        fetch(`${APP_URL}/payments/${id}`, {
            method: 'DELETE',
            credentials: 'include',
            headers: {
                'X-CSRF-TOKEN': getCookie('csrf_access_token')
            },
        })
            .then((response) => {
                if (response.ok) {
                    success('Payment deleted!');
                    navigate('/clients/manage');
                } else {
                    error('Failed to delete payment');
                }
            })
            .catch((e) => error(e));
    }

    if (loading) return <p>Loading payment details...</p>;

    return (
        <div className="p-6">
            <h1 className="text-2xl font-bold mb-4">Edit Payment</h1>
            <div className="mb-4">
                <label className="block text-gray-700">Payment ID</label>
                <input
                    type="text"
                    name="payment_id"
                    value={id}
                    disabled={true}
                    className="w-full p-2 border border-gray-300 rounded-md"
                />
            </div>
            <div className="mb-4">
            <label className="block text-gray-700">Invoice ID</label>
                <input
                    type="text"
                    name="invoice_id"
                    value={formik.values.invoice_id}
                    disabled={true}
                    className="w-full p-2 border border-gray-300 rounded-md"
                />
            </div>
            <div className="mb-4">
                <label className="block text-gray-700">Payment Amount</label>
                <input
                    type="number"
                    name="payment_amount"
                    value={formik.values.payment_amount}
                    onChange={formik.handleChange}
                    className="w-full p-2 border border-gray-300 rounded-md"
                />
            </div>
            <button
                onClick={handleUpdate}
                className="bg-blue-500 text-white py-2 px-4 rounded-md"
            >
                Update Payment
            </button>
            <button
                onClick={handleDelete}
                className="bg-red-500 text-white py-2 px-4 rounded-md ml-2"
            >
                Delete Payment
            </button>
        </div>
    );
};

export default PaymentsForm;
