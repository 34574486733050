import React, { useState, useEffect } from 'react';
import { useParams, Link, useNavigate } from 'react-router-dom';
import { useNotify } from '../context/NotificationContext';
import { useAuth } from '../context/AuthContext';
import '../styles/InvoicePage.css'
import { useUtils } from '../context/UtilContext';

const InvoicePage = () => {
  const { id } = useParams();
  const { APP_URL } = useAuth();
  const { formatCurrency, formatDate } = useUtils()
  const { error } = useNotify();
  const [invoice, setInvoice] = useState([]);
  const [loading, setLoading] = useState(true)
  const [agingReport, setAgingReport] = useState({})
  const navigate = useNavigate();

  useEffect(() => {
    fetch(`${APP_URL}/invoices/${id}`, {
      credentials: 'include'
    })
      .then((resp) => {
        if (resp.ok) {
          resp.json().then((data) => { setInvoice(data); setAgingReport(decode(data.aging_report)) });
          setLoading(false)

        } else {
          if (resp.status === 401 && resp.json) {
            resp.json().then(data => {
              if (!data['msg']) {
                navigate('/access-denied')
              }
            })

          } else {
            navigate('/error')
            error(resp)
          }

        }
      })
      .catch((e) => error(e));
  }, [id]); //eslint-disable-line


  const decode = (encodedString) => {
    const totals = encodedString.split(';')
    let decodedObj = {}

    for (const item of totals) {
      const total = item.split(":")
      const date = total[0]
      const value = total[1]
      decodedObj[date] = value

    }

    window.f = decodedObj
    return decodedObj

  }

  return !loading ?
    (

      // <div className="container mx-auto my-10 p-8 bg-white shadow-lg max-w-4xl">
      //   <Link to={`/clients/${invoice.client_id}`}>
      //     <button className="btn btn-primary print">Back to client</button>
      //   </Link>
      //   <button className="ml-2 btn btn-primary print" onClick={() => window.print()}>Print</button>
      //   {/* <button className="btn btn-danger element-to-hide" onClick={handleDeleteClick}>
      //     Delete invoice
      //   </button> */}
      //   <div className="flex justify-between border-b-2 border-blue-800 pb-6">
      //     <div>
      //       <h1 className="text-3xl font-bold uppercase">
      //         Invoice #{invoice.id}
      //       </h1>
      //       <p className="text-base">
      //         {formatDate(invoice.created_at,true)}
      //       </p>
      //     </div>
      //     <div className="text-right">
      //       <h2 className="text-3xl font-bold uppercase text-blue-800">
      //         Business Accounting
      //       </h2>
      //     </div>
      //   </div>
      //   <div className="flex justify-between items-center mt-8">
      //     <div>
      //       <h3 className="font-bold text-lg">
      //         Payable to
      //       </h3>
      //       <p className="text-base">
      //         Rachel Locke
      //       </p>
      //       <p className="text-base">
      //         123 test street
      //       </p>
      //       <p className="text-base">
      //         Phoenix, Arizona
      //       </p>
      //     </div>
      //     <div className="text-right">
      //       <h3 className="font-bold text-lg">
      //         Client
      //       </h3>
      //       <p className="text-base">
      //         {invoice.client?.legal_name}
      //       </p>
      //       {/* <p className="text-base">

      //       </p> */}
      //       <p className="text-base">
      //         {invoice.client?.email}
      //       </p>
      //     </div>
      //   </div>
      //   <div className="mt-8">
      //     <h3 className="font-bold text-lg border-b-2 border-green-500">
      //       Services
      //     </h3>
      //     <p className="text-base text-center ">
      //       <b>{invoice.text}</b>
      //     </p>
      //     {/* {invoice?.time_entries?.map(timeEntry => {
      //       return (
      //         <React.Fragment key={timeEntry.id}>
      //           <div className="flex justify-between mt-4">
      //             <p className="text-base">
      //               {timeEntry.activity_type?.name}
      //             </p>
      //             <p className="text-base">
      //               {timeEntry.client?.company_name === 'Fester & Chapman' ? "N/A" : formatCurrency(timeEntry.total)}
      //             </p>
      //           </div>
      //           <small>{timeEntry.hours} hours</small>
      //         </React.Fragment>
      //       )
      //     })} */}
      //     <div className="flex justify-between mt-6 border-t-2 border-gray-300 pt-4">
      //       <h3 className="font-bold text-lg">
      //         Total
      //       </h3>
      //       <p className="font-bold text-lg">
      //         {formatCurrency(invoice.total)}
      //       </p>
      //     </div>
      //   </div>
      //   <div className="mt-8">
      //     <p className="text-sm">
      //       Terms &amp; Conditions
      //     </p>
      //     <p className="text-xs mt-2">
      //       A Terms and Conditions agreement is the agreement that includes the terms, the rules and the guidelines of acceptable behavior and other useful sections to which users must agree in order to make the payment.
      //     </p>
      //   </div>
      //   <div className="mt-8 bg-blue-800 text-white text-center py-4 element-to-show">
      //     <h3 className="text-2xl font-bold">
      //       Thank you for being our valued customer!
      //     </h3>
      //   </div>

      // </div>
      <>
        <div className="bg-white mx-auto max-w-3xl shadow-lg border mt-10">
          <div className="px-6 my-10">
            <div className="flex justify-between items-center mb-6">
              <div>
                <h2 className="text-3xl font-bold mb-2">FESTER &&#13; CHAMPAN <small>PLLC</small></h2>
                <h3 className="text-center text-lg font-bold my-2">CERTIFIED PUBLIC ACCOUNTANTS</h3>

              </div>
            </div>

            <div className="py-3 border-t border-b border-gray-400">
              <p className="text-gray-700">{invoice.client?.legal_name}<br />{invoice.client?.address}<br />{invoice.client?.city}, {invoice.client?.state} {invoice.client?.zip_code}</p>
              <p className='text-right'>ID: {invoice.id}<br />Date: {formatDate(invoice.created_at)}</p>
            </div>

            <ul>
              <li className='text-right'>
                <span className='total-label'>{invoice.text}</span>
                {formatCurrency(invoice.total)}
              </li>
              <hr className='f'></hr>
              <li className='text-right'>
                <span className='total-label'>Billed Time & Expenses</span>
                {formatCurrency(invoice.total)}
              </li>
              <hr className='f'></hr>
              <li className='text-right'>
                <span className='total-label'>Invoice Total</span>
                {formatCurrency(invoice.total)}
              </li>
              <hr className='f'></hr>
              <hr className='f'></hr>
              <li className='text-right'>
                <span className='total-label'>Beginning Balance</span>
                {formatCurrency(agingReport['beginning_balance'])}
              </li>
              <li className='text-right'>
                <span className='total-label'>Invoices</span>
                {formatCurrency(invoice.total)}
              </li>
              <hr className='f'></hr>
              <li className='text-right'>
                <span className='total-label'>Amount Due</span>
                {formatCurrency(invoice.total + Number.parseFloat(agingReport['beginning_balance']))}
              </li>
              <hr className='f'></hr>
              <hr className='f'></hr>
            </ul>
            {/* <ul>
              <li>
                <span className='label'>test</span>
              </li>
            </ul> */}
            <table className="w-full text-sm text-gray-700 my-6">
              <tbody>
                <tr>
                  <td className="px-2 py-2 underline">{formatDate(Object.keys(agingReport)[0])}</td>
                  <td className="px-2 py-2 underline">{formatDate(Object.keys(agingReport)[1])}</td>
                  <td className="px-2 py-2 underline">{formatDate(Object.keys(agingReport)[2])}</td>
                  <td className="px-2 py-2 underline">{formatDate(Object.keys(agingReport)[3])}</td>
                  <td className="px-2 py-2 underline">{formatDate(Object.keys(agingReport)[4])}+</td>
                  <td className="px-2 py-2 text-right font-bold underline">Total</td>
                </tr>
                <tr>
                  <td className="px-2 py-2">{formatCurrency(agingReport[Object.keys(agingReport)[0]])}</td>
                  <td className="px-2 py-2">{formatCurrency(agingReport[Object.keys(agingReport)[1]])}</td>
                  <td className="px-2 py-2">{formatCurrency(agingReport[Object.keys(agingReport)[2]])}</td>
                  <td className="px-2 py-2">{formatCurrency(agingReport[Object.keys(agingReport)[3]])}</td>
                  <td className="px-2 py-2">{formatCurrency(agingReport[Object.keys(agingReport)[4]])}</td>
                  <td className="px-2 py-2 text-right font-bold">{formatCurrency(agingReport['total'])}</td>
                </tr>
              </tbody>
            </table>

            <div className="text-sm mb-6">
              <p>If you would like to pay by credit card, please call our office at 602-264-3077. We will add a 3.5% processing fee for all credit card payments.</p>
            </div>

            <div className="flex justify-between items-center">
              <p className="text-sm font-bold" style={{ "color": "rgb(54, 121, 222)" }}>9019 East Bahia Drive Suite 100<br />Scottsdale, AZ 85260</p>
              <p className="text-sm font-bold" style={{ "color": "rgb(54, 121, 222)" }}>Phone: 602-264-3077</p>
            </div>
          </div>
        </div>
      </>
    )
    :
    <h1 className="text-center text-4xl text-gray-700 font-bold mt-10">Loading...</h1>
};

export default InvoicePage;

// style={{"color":"rgb(54, 121, 222)"}}> 