import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import { useNotify } from '../context/NotificationContext';
import * as Yup from 'yup';
import { useFormik } from 'formik';

const InvoiceForm = () => {
    const { id } = useParams(); 
    const { getCookie, APP_URL } = useAuth()
    const { error, success } = useNotify()
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();

    const formik = useFormik({
        initialValues: {
            total: '',
            text: '',
        },
    });

    useEffect(() => {
        fetch(`${APP_URL}/invoices/${id}`, {
            credentials: 'include',
        })
            .then((response) => {
                if (response.ok) {
                    response.json().then(data => {console.log(data.text);formik.setValues({
                        total: data.total,
                        text: data.text
                    })})
                    setLoading(false)
                } else {
                    error(response)
                }
            })
            .catch((e) => {
                error(e)
            });
    }, [id]);

    const handleUpdate = () => {
        fetch(`${APP_URL}/invoices/${id}`, {
            method: 'PATCH',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
                'X-CSRF-TOKEN': getCookie('csrf_access_token')
            },
            body: JSON.stringify(formik.values),
        })
            .then((response) => {
                if (response.ok) {
                    success('Invoice updated successfully!');
                } else {
                    error('Failed to update invoice');
                }
            })
            .catch((e) => error(e));
    };

    const handleDelete = () => {
        fetch(`${APP_URL}/invoices/${id}`, {
            method: 'DELETE',
            credentials: 'include',
            headers: {
                'X-CSRF-TOKEN': getCookie('csrf_access_token')
            },
        })
            .then((response) => {
                if (response.ok) {
                    success('Invoice deleted!');
                    navigate('/clients/manage');
                } else {
                    error('Failed to delete invoice');
                }
            })
            .catch((e) => error(e));
    }

    if (loading) return <p>Loading invoice details...</p>;

    return (
        <div className="p-6">
            <h1 className="text-2xl font-bold mb-4">Edit Invoice</h1>
            <div className="mb-4">
                <label className="block text-gray-700">Invoice ID</label>
                <input
                    type="text"
                    name="invoice_id"
                    value={id}
                    disabled={true}
                    className="w-full p-2 border border-gray-300 rounded-md"
                />
            </div>
            <div className="mb-4">
                <label className="block text-gray-700">Invoice Total</label>
                <input
                    type="number"
                    name="total"
                    value={formik.values.total}
                    onChange={formik.handleChange}
                    className="w-full p-2 border border-gray-300 rounded-md"
                />
            </div>
            <div className="mb-4">
                <label className="block text-gray-700">Invoice Text</label>
                <input
                    type="string"
                    name="text"
                    value={formik.values.text}
                    onChange={formik.handleChange}
                    className="w-full p-2 border border-gray-300 rounded-md"
                />
            </div>
            <button
                onClick={handleUpdate}
                className="bg-blue-500 text-white py-2 px-4 rounded-md"
            >
                Update Invoice
            </button>
            <button
                onClick={handleDelete}
                className="bg-red-500 text-white py-2 px-4 rounded-md ml-2"
            >
                Delete Invoice
            </button>
        </div>
    );
};

export default InvoiceForm;
