import React, { useEffect, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import { useNotify } from '../context/NotificationContext';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Select from 'react-select';

const ContractForm = () => {
    const { id } = useParams(); // Get the contract id from the URL parameters
    const { error, success } = useNotify();
    const [clients, setClients] = useState([]);
    const [searchParams] = useSearchParams();
    const client_id = searchParams.get('client_id');
    const { getCookie, APP_URL } = useAuth();
    const navigate = useNavigate();
    const [isEditMode, setIsEditMode] = useState(false);

    const formik = useFormik({
        initialValues: {
            total: '',
            client: null,
            startDate: '',
            endDate: '',
            // open: 'Open', // Default status
        },
        validationSchema: Yup.object({
            total: Yup.number().min(0, 'Total must be greater than or equal to 0').required('Total is required'),
            client: Yup.object().required('Client is required'),
            startDate: Yup.date().required('Start Date is required'),
            endDate: Yup.date().min(Yup.ref('startDate'), 'End Date cannot be before Start Date'),
            // open: Yup.string().oneOf(['Open', 'Closed'], 'Invalid status').required('Status is required'),
        }),
        onSubmit: values => {
            const payload = {
                client_id: values.client.value,
                total: values.total,
                start_date: values.startDate,
                end_date: values.endDate,
                // open: values.open === 'Open', // Send status in lowercase
            };

            const url = isEditMode ? `${APP_URL}/contracts/${id}` : `${APP_URL}/contracts`;
            const method = isEditMode ? 'PATCH' : 'POST';

            fetch(url, {
                method: method,
                headers: { 'Content-Type': 'application/json', 'X-CSRF-TOKEN': getCookie('csrf_access_token') },
                body: JSON.stringify(payload),
                credentials: 'include'
            })
                .then(resp => {
                    if (resp.ok) {
                        resp.json().then(data => {
                            success(isEditMode ? 'Contract updated successfully!' : 'Contract created successfully!');
                            navigate(`/clients/${values.client.value}`);
                        });
                    } else {
                        resp.json().then(error);
                    }
                })
                .catch(e => {
                    error(e);
                });
        },
    });

    useEffect(() => {
        fetch(`${APP_URL}/clients`, {
            credentials: 'include'
        })
            .then(resp => {
                if (resp.ok) {
                    resp.json().then(data => {
                        setClients(data)
                        if (client_id) {
                            const clientOption = data.find(client => client.id == (client_id)); //eslint-disable-line
                            if (clientOption) {
                                formik.setFieldValue('client',{ value: clientOption.id, label: clientOption.client_id });
                            }
                        }
                    
                    });
                    
                } else {
                    navigate('/error');
                }
            });
    }, []); //eslint-disable-line

    useEffect(() => {
        if (id) {
            setIsEditMode(true);
            fetch(`${APP_URL}/contracts/${id}`, {
                credentials: 'include'
            })
                .then(resp => {
                    if (resp.ok) {
                        resp.json().then(data => {
                            formik.setValues({
                                total: data.total,
                                client: { value: data.client.id, label: `${data.client.legal_name} - ${data.client.client_id}` },
                                startDate: data.start_date,
                                endDate: data.end_date,
                                // open: data.open ? 'Open' : 'Closed', // Capitalize first letter
                            });
                        });
                    } else {
                        navigate('/error');
                    }
                });
        }
    }, [id, clients, APP_URL, navigate]); //eslint-disable-line

    const clientOptions = clients.map(client => {
        return { value: client.id, label: `${client.legal_name} - ${client.client_id}` };
    });

    return (
        <div className="container mt-5">
            <h2 className='text-2xl font-bold'>{isEditMode ? 'Edit Contract' : 'Create Contract'}</h2>
            <form onSubmit={formik.handleSubmit}>
                <div className="mb-3">
                    <label htmlFor="client" className="form-label">
                        Client Account
                    </label>
                    <Select
                        options={clientOptions}
                        value={clientOptions.find(option => option.value === formik.values.client?.value)}
                        onChange={selectedOption => formik.setFieldValue('client', selectedOption)}
                        onBlur={formik.handleBlur}
                        className={`form-control ${formik.touched.client && formik.errors.client ? 'border-red-500' : ''}`}
                        isDisabled={isEditMode} // Disable the client field in edit mode
                    />
                    {formik.touched.client && formik.errors.client && <div className="text-red-500">{formik.errors.client}</div>}
                </div>
                <div className="mb-3">
                    <label htmlFor="total" className="form-label">
                        Total
                    </label>
                    <input
                        type="number"
                        className={`form-control ${formik.touched.total && formik.errors.total ? 'border-red-500' : ''}`}
                        id="total"
                        name="total"
                        value={formik.values.total || ''}
                        min="0"
                        step="1"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        disabled={isEditMode} // Disable the total field in edit mode
                    />
                    {formik.touched.total && formik.errors.total && <div className="text-red-500">{formik.errors.total}</div>}
                </div>
                <div className="mb-3">
                    <label htmlFor="startDate" className="form-label">
                        Start Date
                    </label>
                    <input
                        type="date"
                        className={`form-control ${formik.touched.startDate && formik.errors.startDate ? 'border-red-500' : ''}`}
                        id="startDate"
                        name="startDate"
                        value={formik.values.startDate}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                    />
                    {formik.touched.startDate && formik.errors.startDate && <div className="text-red-500">{formik.errors.startDate}</div>}
                </div>
                <div className="mb-3">
                    <label htmlFor="endDate" className="form-label">
                        End Date
                    </label>
                    <input
                        type="date"
                        className={`form-control ${formik.touched.endDate && formik.errors.endDate ? 'border-red-500' : ''}`}
                        id="endDate"
                        name="endDate"
                        value={formik.values.endDate}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                    />
                    {formik.touched.endDate && formik.errors.endDate && <div className="text-red-500">{formik.errors.endDate}</div>}
                </div>
                {/* <div className="mb-3">
                    <label htmlFor="open" className="form-label">
                        Status
                    </label>
                    <select
                        id="open"
                        name="open"
                        className={`form-control ${formik.touched.open && formik.errors.open ? 'border-red-500' : ''}`}
                        value={formik.values.open}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                    >
                        <option value="Open">Open</option>
                        <option value="Closed">Closed</option>
                    </select>
                    {formik.touched.open && formik.errors.open && <div className="text-red-500">{formik.errors.open}</div>}
                </div> */}
                <button type="submit" className="bg-blue-500 text-white px-4 py-2 rounded-md mr-2">
                    {isEditMode ? 'Update Contract' : 'Create Contract'}
                </button>
            </form>
        </div>
    );
};

export default ContractForm;
