// src/pages/ContractInfo.js
import React, { useState, useEffect } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import { useNotify } from '../context/NotificationContext';
import Select from 'react-select';
import '../styles/ContractInfo.css'; // Import custom CSS for styling
import { useUtils } from '../context/UtilContext';

const ContractInfo = () => {
    const { error } = useNotify();
    const [clients, setClients] = useState([]);
    const [searchParams] = useSearchParams();
    const [selectedClient, setSelectedClient] = useState(null);
    const [client, setClient] = useState(null);
    const { APP_URL } = useAuth();
    const {formatDate, formatCurrency} = useUtils()
    // const navigate = useNavigate();
    const client_id = searchParams.get('client_id');
    const contract_id = searchParams.get('contract_id');
    const [selectedContract, setSelectedContract] = useState(null);

    useEffect(() => {
        fetch(`${APP_URL}/clients`, {
            credentials: 'include'
        })
            .then(resp => {
                if (resp.ok) {
                    resp.json().then(data => {
                        setClients(data);
                        if (client_id) {
                            const clientOption = data.find(client => client.id == client_id); //eslint-disable-line
                            if (clientOption) {
                                setSelectedClient({ value: clientOption.id, label: `${clientOption.client_id} - ${clientOption.legal_name}` });
                            }
                        }
                    });
                } else {
                    error(resp);
                }
            })
            .catch(error);
    }, [client_id, error]); // eslint-disable-line

    useEffect(() => {
        if (selectedClient) {
            setSelectedContract(null);
            fetch(`${APP_URL}/clients/${selectedClient.value}`, {
                credentials: 'include'
            })
                .then(resp => {
                    if (resp.ok) {
                        resp.json().then(data => {
                            setClient(data);
                            if (contract_id) {
                                const contractOption = data.contracts.find(contract => contract.id == contract_id); //eslint-disable-line
                                if (contractOption) {
                                    setSelectedContract({ value: contractOption.id, label: `${contractOption.id}` });
                                }
                            }
                        });
                    } else {
                        error(resp);
                    }
                })
                .catch(error);
        }
    }, [selectedClient, contract_id, error]); // eslint-disable-line

    const clientOptions = clients.map(client => ({
        value: client.id,
        label: `${client.client_id} - ${client.legal_name}`,
    }));

    const contractOptions = client?.contracts?.map(contract => ({
        value: contract.id,
        label: `${contract.id}`,
    }));

    const currentContract = selectedContract && selectedContract.value && client?.contracts?.find(contract => contract.id === selectedContract.value);

    const invoicesForContract = client?.invoices.filter(invoice => invoice.contract_id === selectedContract?.value);

    return (
        <div className="contract-container mt-5">
            <h2 className="contract-heading">Contract Overview</h2>
            <div className="contract-form-group">
                <label htmlFor="client" className="contract-form-label">Select Client</label>
                <Select
                    options={clientOptions}
                    value={selectedClient}
                    onChange={setSelectedClient}
                    className="contract-form-select"
                />
            </div>
            <div className="contract-form-group">
                <label htmlFor="contract" className="contract-form-label">Select Contract</label>
                <Select
                    options={contractOptions}
                    value={selectedContract}
                    onChange={setSelectedContract}
                    className="contract-form-select"
                />
            </div>
            {selectedContract && currentContract && (
                <div className="contract-info">
                    <h3 className="contract-subheading">Contract Information</h3>
                    <div><strong>Contract ID:</strong> {currentContract.id}</div>
                    <div><strong>Start Date:</strong> {formatDate(currentContract.start_date)}</div>
                    <div><strong>End Date:</strong> {currentContract.end_date ? formatDate(currentContract.end_date) : 'N/A'}</div>
                    <div><strong>Contract Status:</strong> {currentContract.open ? 'Open' : 'Closed'}</div>
                    <div><strong>Total:</strong> {formatCurrency(currentContract.total)}</div>
                    <div><strong>Remaining:</strong> {formatCurrency(currentContract.remaining_total)}</div>
                    <Link to={`/contracts/${currentContract.id}/summary`} className="contract-btn">Contract Summary</Link>
                    <Link to={`/contracts/${currentContract.id}/edit`} className="contract-btn ml-2">Edit Contract</Link>
                </div>
            )}
            {selectedContract && client?.invoices && (
                <div>
                    <h3 className="contract-subheading">Invoices for {selectedClient?.label}</h3>
                    {invoicesForContract && invoicesForContract.length > 0 ? (
                        <ul className="contract-invoice-list">
                            {invoicesForContract.map(invoice => (
                                <li className="contract-invoice-item" key={invoice.id}>
                                    <div className="contract-invoice-details">
                                        <div className="contract-invoice-id"><strong>Invoice ID:</strong> {invoice.id} - {invoice.remaining_total === 0 ? "Paid" : "Unpaid"}</div>
                                        <div className="contract-invoice-total"><strong>Total:</strong> {formatCurrency(invoice.total)}</div>
                                    </div>
                                    {invoice.payments.length > 0 ? (
                                        <table className="contract-payment-table">
                                            <thead>
                                                <tr>
                                                    <th>Payment ID</th>
                                                    <th>Payment Amount</th>
                                                    <th>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {invoice.payments.map(payment => (
                                                    <tr key={payment.id}>
                                                        <td>{payment.id}</td>
                                                        <td>{formatCurrency(payment.payment_amount)}</td>
                                                        <td><Link to={`/payments/${payment.id}/edit`}>Edit</Link></td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    ) : (
                                        <>
                                            <div className="contract-no-payment">No payments found for this invoice.</div>
                                            <Link to={`/clients/${currentContract.client.id}/payments?contract_id=${currentContract.id}&invoice_id=${invoice.id}`} className="contract-btn mt-2">Make A Payment</Link>
                                        </>
                                    )}
                                </li>
                            ))}
                        </ul>
                    ) : (
                        <div className="contract-no-invoice">No invoices found for the selected contract.</div>
                    )}
                </div>
            )}
        </div>
    );
};

export default ContractInfo;
