import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useAuth } from "../context/AuthContext";
import { useNotify } from "../context/NotificationContext";
import '../styles/Client.css'; // Import custom CSS for styling
import { useUtils } from "../context/UtilContext";

const Client = () => {
    const [activeTab, setActiveTab] = useState(1);
    const { id } = useParams();
    const navigate = useNavigate();
    const [client, setClient] = useState({});
    const { user, APP_URL } = useAuth();
    const { formatDate, formatCurrency, sum } = useUtils()
    const { error } = useNotify();
    const [showTimeEntries, setShowTimeEntries] = useState(false);
    const [showInvoices, setShowInvoices] = useState(false);
    const [showContracts, setShowContracts] = useState(false);

    useEffect(() => {
        fetch(`${APP_URL}/clients/${id}`, {
            credentials: 'include'
        })
            .then(resp => {
                if (resp.ok) {
                    resp.json().then(data => {
                        setClient(data);
                    });
                } else {
                    if (resp.status === 401 && resp.json) {
                        resp.json().then(data => {
                            if (!data['msg']) {
                                navigate('/access-denied');
                            }
                        });
                    } else {
                        navigate('/error');
                        error(resp);
                    }
                }
            })
            .catch(e => error(e));
    }, [id]); //eslint-disable-line

    const clientTimeEntries = client.time_entries?.map(timeEntry =>
        <tr key={timeEntry.id} className="client-table-row" style={{ cursor: 'pointer', backgroundColor: timeEntry.invoice?.id ? '#F0ECEB' : '' }}>
            <td>{timeEntry.id}</td>
            <td>{timeEntry.activity_type?.name}</td>
            <td>{formatDate(timeEntry.start_date) || ''}</td>
            <td>{timeEntry.user?.username}</td>
            {user?.role === 'Partner' && (
                <td>
                    <button disabled={timeEntry.invoice?.id} className="btn btn-secondary-client" onClick={(e) => { e.stopPropagation(); navigate(`/time_entries/${timeEntry.id}/edit`) }}>Edit</button>
                </td>
            )}
        </tr>
    );

    const filteredTimeEntries = clientTimeEntries?.filter(timeEntry => {
        if (showTimeEntries) {
            return true;
        }
        return !timeEntry.contract?.end_date;
    });

    const clientInvoices = client.invoices?.map(invoice =>
        <tr key={invoice.id} className="client-table-row" style={{ cursor: 'pointer' }}>
            <td>{invoice.id}</td>
            <td>{invoice.completed ? 'Completed' : 'In Progress'}</td>
            <td>{invoice.total !== null ? formatCurrency(invoice.total) : 'N/A'}</td>
            <td>
                <button className="btn btn-secondary-client" onClick={(e) => { e.stopPropagation(); navigate(`/invoices/${invoice.id}`) }}>View</button>
            </td>
            <td><Link to={`/invoices/${invoice.id}/edit`}>Edit</Link></td>
        </tr>
    );

    const filteredInvoices = clientInvoices?.filter(invoice => {
        if (showInvoices) {
            return true;
        }
        return !invoice.contract?.end_date;
    });

    const clientContracts = client.contracts?.map(contract =>
        <tr key={contract.id} className="client-table-row" style={{ cursor: 'pointer' }}>
            <td>{contract.id}</td>
            <td>{formatCurrency(contract.total)}</td>
            <td>{formatCurrency(contract.remaining_total)}</td>
            <td>{formatDate(contract.start_date)}</td>
            <td>{formatDate(contract.end_date)}</td>
            <td>{contract.open ? 'Open' : 'Closed'}</td>
            <td>
                <Link to={`/contracts/manage?client_id=${contract.client?.id}&contract_id=${contract.id}`}>View</Link>
            </td>
        </tr>
    );

    const filteredContracts = clientContracts?.filter(contract => {
        if (showContracts) {
            return true;
        }
        return !contract.end_date;
    });

    const payments = []

    if (client.invoices) {
        client.invoices.forEach(invoice => {
            if (invoice.payments) {
                invoice.payments.forEach(payment => payments.push({ ...payment, invoice: { id: invoice.id } }))
            }
        })
    }


    return client.legal_name ? (
        <div className="container bg-white border my-3">
            <div className="client-header">
                <h2 className="text-2xl font-bold">Client Info</h2>
                {user?.role === 'Partner' && (
                    <div className="button-group">
                        <Link className="btn btn-secondary" to={`/clients/${id}/edit`}>Edit Client</Link>
                        <Link className="btn btn-primary" to={`/clients/${client.id}/wip`}>WIP Queue</Link>
                        <Link className="btn btn-info" to={`/clients/${client.id}/payments`}>Record Payment</Link>
                        <Link className="btn btn-secondary" to={`/clients/${client.id}/activity-types`}>Manage Activities</Link>
                        <Link className="btn btn-secondary" to={`/contracts/create?client_id=${client.id}`}>Create Contracts</Link>
                        <Link className="btn btn-secondary" to={`/contracts/manage?client_id=${client.id}`}>Manage Contracts</Link>
                    </div>
                )}
            </div>

            <div className="client-info">
                <div className="client-info-item">
                    <h3>Client ID</h3>
                    <p>{client.client_id}</p>
                </div>
                <div className="client-info-item">
                    <h3>Legal Name</h3>
                    <p>{client.legal_name}</p>
                </div>
                <div className="client-info-item">
                    <h3>Main Contact</h3>
                    <p>{client.main_contact}</p>
                </div>
                <div className="client-info-item">
                    <h3>Main Email</h3>
                    <p>{client.main_email}</p>
                </div>
                <div className="client-info-item">
                    <h3>Billing Contact</h3>
                    <p>{client.billing_contact}</p>
                </div>
                <div className="client-info-item">
                    <h3>Billing Email</h3>
                    <p>{client.billing_email}</p>
                </div>
                <div className="client-info-item">
                    <h3>Email</h3>
                    <p>{client.email}</p>
                </div>
                <div className="client-info-item">
                    <h3>Phone Number</h3>
                    <p>{client.phone}</p>
                </div>
                <div className="client-info-item">
                    <h3>Start Date</h3>
                    <p>{formatDate(client.start_date)}</p>
                </div>
                <div className="client-info-item">
                    <h3>Status</h3>
                    <p>{client.status}</p>
                </div>
                <div className="client-info-item">
                    <h3>State</h3>
                    <p>{client.state}</p>
                </div>
                <div className="client-info-item">
                    <h3>City</h3>
                    <p>{client.city}</p>
                </div>
                <div className="client-info-item">
                    <h3>Zip Code</h3>
                    <p>{client.zip_code}</p>
                </div>
                <div className="client-info-item">
                    <h3>Address</h3>
                    <p>{client.address}</p>
                </div>
                <div className="client-info-item">
                    <h3>Group</h3>
                    <p>{client.group}</p>
                </div>
                <div className="client-info-item">
                    <h3>EIN</h3>
                    <p>{client.ein}</p>
                </div>
                <div className="client-info-item">
                    <h3>Partner</h3>
                    <p>{client.partner ? client.partner.username : 'N/A'}</p>
                </div>
                <div className="client-info-item">
                </div>
            </div>

            <ul className="nav nav-tabs-client">
                <li className="nav-item-client">
                    <button className={`nav-link-client ${activeTab === 1 ? "active" : ""}`} onClick={() => setActiveTab(1)}>Summary</button>
                </li>
                {/* {user?.role === 'Partner' && ( */}
                <li className="nav-item-client">
                    <button className={`nav-link-client ${activeTab === 2 ? "active" : ""}`} onClick={() => setActiveTab(2)}>Contracts</button>
                </li>
                <li className="nav-item-client">
                    <button className={`nav-link-client ${activeTab === 3 ? "active" : ""}`} onClick={() => setActiveTab(3)}>Time And Expenses</button>
                </li>
                <li className="nav-item-client">
                    <button className={`nav-link-client ${activeTab === 4 ? "active" : ""}`} onClick={() => setActiveTab(4)}>Invoices</button>
                </li>
                <li className="nav-item-client">
                    <button className={`nav-link-client ${activeTab === 5 ? "active" : ""}`} onClick={() => setActiveTab(5)}>Payments</button>
                </li>
                {/* )} */}
            </ul>


            <div className="tab-content-client">
                {activeTab === 1 && (
                    <div className={`tab-pane-client ${activeTab !== 1 ? 'fade' : ''} active`} id="summaryTab">
                        <table className="table-client">
                            <thead>
                                <tr>
                                    <th>Contracts</th>
                                    <th>Time Entries</th>
                                    <th>Invoices</th>
                                    <th>Payments</th>
                                    <th>WIP</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr className="client-table-row" style={{ cursor: 'pointer' }}>
                                    <td>{formatCurrency(sum(client.contracts, 'total'))}</td>
                                    <td>{formatCurrency(sum(client.time_entries, 'total'))}</td>
                                    <td>{formatCurrency(sum(client.invoices, 'total'))}</td>
                                    <td>{formatCurrency(sum(payments, 'payment_amount'))}</td>
                                    <td>{formatCurrency(client.wip)}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                )}
                {activeTab === 2 && (
                    <div className={`tab-pane-client ${activeTab !== 2 ? 'fade' : ''} active`} id="contractsTab">
                        <button className="btn btn-primary-client my-3" onClick={() => setShowContracts(current => !current)}>{showContracts ? "Show Active Contracts" : "Show All Contracts"}</button>
                        <div className="table-responsive">
                            <table className="table table-striped table-bordered">
                                <thead>
                                    <tr>
                                        <th>Contract ID</th>
                                        <th>Total</th>
                                        <th>Remaining Total</th>
                                        <th>Start Date</th>
                                        <th>End Date</th>
                                        <th>Status</th>
                                        <th>Details</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {clientContracts && client.contracts?.length ? (
                                        filteredContracts.length ? (
                                            filteredContracts
                                        ) : (
                                            <tr>
                                                <td colSpan="5" className="text-center">
                                                    <div className="alert alert-info-client" role="alert">
                                                        There are no open contracts.
                                                    </div>
                                                </td>
                                            </tr>
                                        )
                                    ) : (
                                        <tr>
                                            <td colSpan="5" className="text-center">
                                                <div className="alert alert-info-client" role="alert">
                                                    There are no contracts for this client.
                                                </div>
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </div>
                    </div>
                )}
                {activeTab === 3 && (
                    <div className={`tab-pane-client ${activeTab !== 3 ? 'fade' : ''} active`} id="timeEntriesTab">
                        <button className="btn btn-primary-client my-3" onClick={() => setShowTimeEntries(current => !current)}>{showTimeEntries ? "Show Active Time Entries" : "Show All Time Entries"}</button>
                        <table className="table-client">
                            <thead>
                                <tr>
                                    <th>Time Entry ID</th>
                                    <th>Activity</th>
                                    <th>Start Date</th>
                                    <th>User</th>
                                    {user?.role === 'Partner' && <th>Actions</th>}
                                </tr>
                            </thead>
                            <tbody>
                                {clientTimeEntries && client.time_entries?.length ? (
                                    filteredTimeEntries.length ? (
                                        filteredTimeEntries
                                    ) : (
                                        <tr>
                                            <td colSpan="5" className="text-center">
                                                <div className="alert alert-info-client" role="alert">
                                                    There are no time entries with open contracts.
                                                </div>
                                            </td>
                                        </tr>
                                    )
                                ) : (
                                    <tr>
                                        <td colSpan="5" className="text-center">
                                            <div className="alert alert-info-client" role="alert">
                                                There are no time entries for this client.
                                            </div>
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>

                )}
                {activeTab === 4 && (
                    <div className={`tab-pane-client ${activeTab !== 4 ? 'fade' : ''} active`} id="invoicesTab">
                        <button className="btn btn-primary-client my-3" onClick={() => setShowInvoices(current => !current)}>{showInvoices ? "Show Active Invoices" : "Show All Invoices"}</button>
                        <table className="table-client">
                            <thead>
                                <tr>
                                    <th>Invoice ID</th>
                                    <th>Status</th>
                                    <th>Total</th>
                                    <th>Action</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {clientInvoices && client.invoices?.length ? (
                                    filteredInvoices.length ? (
                                        filteredInvoices
                                    ) : (
                                        <tr>
                                            <td colSpan="4" className="text-center">
                                                <div className="alert alert-info-client" role="alert">
                                                    There are no invoices with open contracts.
                                                </div>
                                            </td>
                                        </tr>
                                    )
                                ) : (
                                    <tr>
                                        <td colSpan="4" className="text-center">
                                            <div className="alert alert-info-client" role="alert">
                                                There are no invoices for this client.
                                            </div>
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                )}
                {activeTab === 5 && (
                    <div className={`tab-pane-client ${activeTab !== 5 ? 'fade' : ''} active`} id="paymentsTab">
                        <table className="table-client">
                            <thead>
                                <tr>
                                    <th>Payment ID</th>
                                    <th>Payment Amount</th>
                                    <th>Created At</th>
                                    <th>Invoice ID</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {payments.length ? payments.map(payment =>
                                    <tr key={payment.id} className="client-table-row" style={{ cursor: 'pointer' }}>
                                        <td>{payment.id}</td>
                                        <td>{formatCurrency(payment.payment_amount)}</td>
                                        <td>{formatDate(payment.created_at)}</td>
                                        <td><Link to={`/invoices/${payment.invoice.id}/edit`}>{payment.invoice.id}</Link></td>
                                        <td><Link to={`/payments/${payment.id}/edit`}><button className="btn btn-secondary-client">Edit</button></Link></td>
                                    </tr>
                                ) : (
                                    <tr>
                                        <td colSpan="4" className="text-center">
                                            <div className="alert alert-info-client" role="alert">
                                                There are no payments on any invoice.
                                            </div>
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                )}
            </div>
        </div>
    ) : <h1 className="text-center text-4xl text-gray-700 font-bold mt-10">Loading...</h1>;
};

export default Client;
